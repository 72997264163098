(() => {
    window.mapLoaded = false

    const isInViewport = (el, percentVisible) => {
        if (!el) return false

        let rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight)

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        )
    };

    const loadMap = () => {
        if (window.mapLoaded || !isInViewport(document.querySelector('[data-map-holder]'), 4))
            return

        window.mapLoaded = true
        if (!window.wpObject?.gMapsApi) {
            console.error("Nemáte vyplněný API klíč pro Google Maps.")
            return
        }

        const gMapsJs = document.createElement('script')
        gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=${window.wpObject.gMapsApi}&callback=initMap&libraries=&v=weekly`

        document.body.insertAdjacentElement('beforeend', gMapsJs)
    }

    document.addEventListener("DOMContentLoaded", loadMap);
    document.addEventListener("scroll", loadMap);
    window.loadMap = loadMap
})();

const initMap = () => {
    const mapEl = document.querySelector('[data-map-holder]') as HTMLElement
    if (!mapEl) return

    const officeCoordinates = window.wpObject.mapPosition.replace(' ', '').split(','),
        officeAddress = {lat: parseFloat(officeCoordinates[0] ?? '0'), lng: parseFloat(officeCoordinates[1] ?? '0')}

    const mapOffice = new google.maps.Map(mapEl, {
        center: officeAddress,
        zoom: 15,
        disableDefaultUI: true,
        styles: [
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#999999"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ]
    });

    const marker = new google.maps.Marker({
        position: officeAddress,
        icon: window.wpObject.mapIcon,
        map: mapOffice
    });

    marker.addListener("click", () => {
        window.open(`${ window.wpObject.mapAddressUrl }`, "_blank")
    });


    mapOffice.addListener("tilesloaded", () => mapEl.classList.remove('is-loading'))
}

declare global {
    interface Window {
        loadMap: () => void
        initMap: () => void
        mapLoaded: boolean
        wpObject: {
            gMapsApi: string
            mapPosition: string
            mapAddressUrl: string
            mapIcon: string
            mapBackgroundColor: string
            mapPrimaryColor: string
        }
    }
}

window.initMap = initMap